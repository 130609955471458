import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Project'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Project`}</h1>
    <div style={{
      marginBottom: 20
    }}>
    <a href="https://npmjs.org/package/@arwes/core" target='_blank'>
        <img src="https://img.shields.io/npm/v/@arwes/core.svg" alt="version" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://github.com/arwes/arwes/actions" target='_blank'>
        <img src="https://github.com/arwes/arwes/workflows/ci/badge.svg" alt="ci" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://github.com/arwes/arwes" target='_blank'>
        <img src="https://img.shields.io/github/stars/arwes/arwes.svg?style=social&label=stars" alt="github stars" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://npmjs.org/package/@arwes/core" target='_blank'>
        <img src="https://img.shields.io/npm/dm/@arwes/core.svg" alt="downloads" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://twitter.com/arwesjs" target='_blank'>
        <img src="https://img.shields.io/twitter/follow/arwesjs.svg?label=%40arwesjs&style=social" alt="Twitter Follow" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://discord.gg/s5sbTkw" target='_blank'>
        <img src="https://img.shields.io/badge/chat-discord-blue.svg" alt="chat on discord" style={{
          display: 'inline-block'
        }} />
    </a>
    </div>
    <div style={{
      marginBottom: 20
    }}>
    <a href="https://standardjs.com" target='_blank'>
        <img src="https://img.shields.io/badge/code_style-standardjs-d2bb09.svg" alt="code style: standardjs" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://jestjs.io" target='_blank'>
        <img src="https://img.shields.io/badge/tested%20with-jest-15c213.svg" alt="tested with: jest" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://lerna.js.org" target='_blank'>
        <img src="https://img.shields.io/badge/maintained%20with-lerna-cc00ff.svg" alt="lerna" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="http://commitizen.github.io/cz-cli" target='_blank'>
        <img src="https://img.shields.io/badge/commitizen-friendly-brightgreen.svg" alt="Commitizen friendly" style={{
          display: 'inline-block'
        }} />
    </a>
    {' '}
    <a href="https://github.com/arwes/arwes/blob/main/LICENSE" target='_blank'>
        <img src="https://img.shields.io/github/license/arwes/arwes.svg?maxAge=2592000" alt="license" style={{
          display: 'inline-block'
        }} />
    </a>
    </div>
    <p><a parentName="p" {...{
        "href": "https://arwes.dev"
      }}>{`Arwes`}</a>{` is a web framework to build user interfaces based on
futuristic science fiction designs, animations, and sound effects. The concepts
behind are opinionated with influences from `}<a parentName="p" {...{
        "href": "https://aesthetics.fandom.com/wiki/Cyberprep"
      }}>{`Cyberprep`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Cyberpunk"
      }}>{`Cyberpunk`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Synthwave"
      }}>{`Synthwave`}</a>{`,
and productions like `}<a parentName="p" {...{
        "href": "http://robertsspaceindustries.com"
      }}>{`Star Citizen`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://www.halowaypoint.com/en-us/games"
      }}>{`Halo`}</a>{`, and `}<a parentName="p" {...{
        "href": "http://www.imdb.com/title/tt1104001"
      }}>{`TRON: Legacy`}</a>{`.
It tries to inspire advanced space and alien technology. See `}<a parentName="p" {...{
        "href": "/project/purpose"
      }}>{`Purpose`}</a>{`.`}</p>
    <p>{`The project defines `}<a parentName="p" {...{
        "href": "/project/guidelines"
      }}>{`a set of UI/UX guidelines`}</a>{`, uses
`}<a parentName="p" {...{
        "href": "/design"
      }}>{`many inspiration resources`}</a>{`, and it is delivered as `}<a parentName="p" {...{
        "href": "/develop"
      }}>{`a collection of packages`}</a>{`.`}</p>
    <p>{`This website, `}<a parentName="p" {...{
        "href": "https://arwes.dev"
      }}>{`arwes.dev`}</a>{`, shows the work in progress of the
documentation for the project latest version which is under active development
at `}<a parentName="p" {...{
        "href": "https://github.com/arwes/arwes"
      }}><inlineCode parentName="a">{`main`}</inlineCode></a>{` branch with the packages `}<inlineCode parentName="p">{`@arwes/[package]`}</inlineCode>{`
with major breaking changes from the previous version. It is still in
`}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/40067469"
      }}>{`alpha release`}</a>{`, so the components
are being tested and their API may change as it gets completed.`}</p>
    <blockquote>
      <p parentName="blockquote">{`As it is now, the website has a simple layout until the first beta version is
released and the components can be properly implemented in this website.`}</p>
    </blockquote>
    <p>{`The project is not fully open for sustancial contributions yet until the
first beta version is released and the `}<a parentName="p" {...{
        "href": "/project/guidelines"
      }}>{`project guidelines`}</a>{`
are finished. But you can follow the development in `}<a parentName="p" {...{
        "href": "https://discord.gg/s5sbTkw"
      }}>{`Discord`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://twitter.com/arwesjs"
      }}>{`Twitter`}</a>{`. For more details, read
`}<a parentName="p" {...{
        "href": "/project/faq"
      }}>{`FAQ`}</a>{` and `}<a parentName="p" {...{
        "href": "/project/roadmap"
      }}>{`Roadmap`}</a>{`.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://playground.arwes.dev"
      }}>{`playground`}</a>{` application intends to show the
project APIs in a live environment so the developer can test the main functionalities
and components use cases in JavaScript (not available in TypeScript) for both
development and production environments.`}</p>
    <p>{`The project has a working version with the package `}<a parentName="p" {...{
        "href": "https://npmjs.org/package/arwes"
      }}><inlineCode parentName="a">{`arwes`}</inlineCode></a>{`
`}<inlineCode parentName="p">{`v1.0.0-alpha.5`}</inlineCode>{` and the documentation with the website `}<a parentName="p" {...{
        "href": "https://version1-breakpoint1.arwes.dev"
      }}>{`version1-breakpoint1.arwes.dev`}</a>{`.
Check out the source code at `}<a parentName="p" {...{
        "href": "https://github.com/arwes/arwes/tree/version1-breakpoint1"
      }}><inlineCode parentName="a">{`version1-breakpoint1`}</inlineCode></a>{`
branch where you can find the components and the website. This package was released
in February, 2018, and it is now deprecated.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      